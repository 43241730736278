<template>
  <div class="paging" v-if="totalElements !== 0">
    <a
      @click="goPage(currentPageIndex - 1)"
      href="javascript:void(0);"
      class="btn"
    >
      <i class="el-icon-arrow-left"></i>
    </a>
    <a
      v-for="index in endPageIndex - startPageIndex + 1"
      :key="index"
      href="javascript:void(0);"
      @click="goPage(startPageIndex + index - 1)"
      :class="{ on: startPageIndex + index - 1 == currentPageIndex }"
      >{{ startPageIndex + index - 1 }}</a
    >
    <!--a href="#a" class="">2</a-->
    <a
      @click="goPage(currentPageIndex + 1)"
      href="javascript:void(0);"
      class="btn"
    >
      <i class="el-icon-arrow-right"></i>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    totalElements: {
      type: Number,
      default: 0,
    },
    currentPageIndex: {
      type: Number,
      default: 5,
    },
    listRowCount: {
      type: Number,
      default: 20,
    },
    pageLinkCount: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      pageCount: 0,
      startPageIndex: 1,
      endPageIndex: 5,
      prev: false,
      next: false,
    }
  },
  methods: {
    goPage(curPage) {
      //endPageIndex(5) -> pageCount(전체 페이지 수)로 변경
      if (curPage === 0 || curPage > this.pageCount) return
      if (curPage === this.currentPageIndex) return
      this.$emit('goPage', curPage)
      //console.log('pageLinkCount(5 고정) = ' + this.pageLinkCount)
      //console.log('currentPageIndex(현재 페이지) = ' + this.currentPageIndex)
      //console.log('pageCount(전체 페이지) = ' + this.pageCount)
      //console.log(
      //  'endPageIndex(현재 보여지는 마지막 페이지의 수) =' + this.endPageIndex,
      //)
      //console.log(
      //  'startPageIndex(현재 보여지는 첫번째 페이지의 수) = ' +
      //    this.startPageIndex,
      //)
      //console.log('pageLinkCount(전체 페이지) = ' + this.pageLinkCount)
    },

    initUI() {
      //전체 페이지는 전체 목록의 갯수 나누기 10(한페이지에 보여지는 갯수)
      this.pageCount = Math.ceil(this.totalElements / this.listRowCount)
      //현재페이지에서 5(한번에 보여질 최대 번호 갯수)를 나누고 나머지 값이 0과 같을 때 ex) 5, 10, 15 ... 5의 배수
      if (this.currentPageIndex % this.pageLinkCount == 0) {
        //페이지에서 보여지는 첫번째 페이지의 값은
        this.startPageIndex =
          //(현재페이지 나누기 5 - 1) 곱하기 5 + 1
          (this.currentPageIndex / this.pageLinkCount - 1) *
            this.pageLinkCount +
          1
        //5의 배수가 아닐 경우
      } else {
        //페이지에서 보여지는 첫번째 페이지의 값은
        this.startPageIndex =
          //(현재 페이지 나누기 5) 곱하기 5 + 1
          Math.floor(this.currentPageIndex / this.pageLinkCount) *
            this.pageLinkCount +
          1
      }
      if (this.currentPageIndex % this.pageLinkCount == 0) {
        this.endPageIndex =
          (this.currentPageIndex / this.pageLinkCount - 1) *
            this.pageLinkCount +
          this.pageLinkCount
      } else {
        this.endPageIndex =
          Math.floor(this.currentPageIndex / this.pageLinkCount) *
            this.pageLinkCount +
          this.pageLinkCount
      }
      if (this.endPageIndex > this.pageCount) {
        this.endPageIndex = this.pageCount
      }
      if (this.currentPageIndex <= this.pageLinkCount) {
        this.prev = false
      } else {
        this.prev = true
      }
      if (this.endPageIndex >= this.pageCount) {
        this.endPageIndex = this.pageCount
        this.next = false
      } else {
        this.next = true
      }
    },
  },
  watch: {
    totalElements: {
      immediate: true,
      handler() {
        this.initUI()
      },
    },
  },
  mounted() {
    setInterval(this.initUI, 500)
  },
}
</script>
