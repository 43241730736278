export default {
  methods: {
    initDatePicker(elementId, callback) {
      this.$nextTick(() => {
        window.$(`#${elementId}`).datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: selectedDate => {
            callback(selectedDate)
          },
        })
      })
    },

    setDatePickerDate(elementId, date, callback) {
      window
        .$(`#${elementId}`)
        .datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: selectedDate => {
            callback(selectedDate)
          },
        })
        .datepicker('setDate', new Date(date))
    },

    diffCalendar(stDt, endDt) {
      const startDate = new Date(stDt)
      const endDate = new Date(endDt)

      const result = endDate.getTime() - startDate.getTime()
      return result / (1000 * 60 * 60 * 24) + 1 //일기준
    },
    diffDateCalendar(stDt, SetMonth) {
      const now = new Date()

      const startDate = new Date(stDt)

      const year = now.getFullYear() // 년
      const month = now.getMonth() // 월
      const day = now.getDate() // 일

      const CriteriaMonth = new Date(year, month - SetMonth, day)

      if (startDate < CriteriaMonth) {
        return false
      }
    },

    diffTime(stDt, endDt) {
      const startDate = new Date(stDt)
      const endDate = new Date(endDt)

      const result = endDate.getTime() - startDate.getTime()
      return result
    },
  },
}
