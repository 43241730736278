<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit req-type">
        <h1>마일리지 내역</h1>
        <p class="stit">고객님의 마일리지 내역을 확인하세요.</p>
      </div>
      <mileage-history-list></mileage-history-list>
      <div class="sub-cont">
        <div class="mileage-tabmenu-wrap">
          <ul style="cursor: pointer">
            <li>
              <a @click="switchLeftTab" :class="{ on: isVisibleLeftTab }"
                >등급 적립 항목</a
              >
            </li>
            <li>
              <a @click="switchRightTab" :class="{ on: !isVisibleLeftTab }"
                >등급별 혜택</a
              >
            </li>
          </ul>
        </div>
        <mileage-example
          :mileageInfoTbl="mileageInfoTbl"
          :mileagePercentInfoTbl="mileagePercentInfoTbl"
          :isVisibleLeftTab="isVisibleLeftTab"
        ></mileage-example>
      </div>
    </div>
  </main>
</template>

<script>
import { getMileageInfo, getMileagePercentInfo } from 'Api/modules'
import MileageExample from '../partials/MileageExample'
import MileageHistoryList from '../partials/MileageHistoryList'

export default {
  components: {
    MileageExample,
    MileageHistoryList,
  },
  data() {
    return {
      mileageInfoTbl: [],
      mileagePercentInfoTbl: [],
      isVisibleLeftTab: true,
    }
  },
  methods: {
    switchLeftTab() {
      this.isVisibleLeftTab = true
    },
    switchRightTab() {
      this.isVisibleLeftTab = false
    },
  },
  async created() {
    const mileageInfo = await getMileageInfo()
    this.mileageInfoTbl = mileageInfo
    const mileagePercentInfo = await getMileagePercentInfo()
    this.mileagePercentInfoTbl = mileagePercentInfo
  },
}
</script>

<style></style>
