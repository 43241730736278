<template>
  <div class="myp-buy-wrap">
    <div class="search-item-wrap">
      <div class="label">발생기간</div>
      <div class="date-item-wrap">
        <input
          type="text"
          class="inp datepicker"
          id="startDt"
          :value="startDt"
          inputmode="none"
        />
        <span class="e01">-</span>
        <input
          type="text"
          class="inp datepicker"
          inputmode="none"
          id="endDt"
          :value="endDt"
        />
      </div>
      <button type="button" @click="search">검색</button>
    </div>
    <table class="tbl01">
      <colgroup>
        <col width="180" />
        <col width="auto" />
        <col width="120" />
      </colgroup>
      <tr>
        <th>발생 일자</th>
        <th>경로</th>
        <th>발생 마일리지</th>
      </tr>
      <tr v-for="(item, idx) in mileageHistoryList" :key="`st_${idx}`">
        <td>{{ item.issueDt }}</td>
        <td>{{ item.mileagePath }}</td>
        <td style="text-align: right">
          {{ `${insertCommaToNumber(item.mileage)}` }}
        </td>
      </tr>
      <tr>
        <td colspan="3" v-if="mileageHistoryList.length == 0">
          해당내역이 존재하지 않습니다.
        </td>
      </tr>
    </table>

    <!-- 페이징 추가 -->
    <Pagination
      :totalElements="totalElements"
      :currentPageIndex="currentPageIndex"
      :listRowCount="listRowCount"
      :pageLinkCount="pageLinkCount"
      @goPage="onGoPage"
    ></Pagination>
  </div>
</template>

<script>
import { getMileageHistoryList } from 'Api/modules'
import datePickerMixin from 'Mixins/datePicker'
import Pagination from 'Common/Pagination'
import moment from 'moment'
export default {
  components: {
    Pagination,
  },
  mixins: [datePickerMixin],
  data() {
    return {
      startDt: '',
      endDt: '',
      mileageHistoryList: [],
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
    }
  },
  watch: {
    startDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.endDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.endDt) - 1 < 0) {
            this.setDatePickerDate('endDt', newVal, this.setEndDate)
            this.endDt = newVal
          }
        }
      }
    },
    endDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.startDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.startDt, newVal) - 1 < 0) {
            this.setDatePickerDate('startDt', newVal, this.setStartDate)
            this.startDt = newVal
          }
        }
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    setFromQueryParam() {
      const { startDt, endDt, pageNumber } = this.$route.query
      this.startDt = startDt
      this.setDatePickerDate('startDt', startDt, this.setStartDate)
      this.endDt = endDt
      this.setDatePickerDate('endDt', endDt, this.setEndDate)
      this.pageNumber = pageNumber

      if (!this.startDt && !this.endDt) {
        const today = moment().format('YYYY-MM-DD')
        this.setDatePickerDate('startDt', today, this.setStartDate)
        this.startDt = today
        this.setDatePickerDate('reqEndDt', today, this.setEndDate)
        this.endDt = today
      }
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    setStartDate(selectedDate) {
      this.startDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.endDt = selectedDate
    },
    async search(setQuery = true) {
      if (!this.startDt || !this.endDt) {
        return false
      }
      //마일리지 내역 조회
      const res = await getMileageHistoryList({
        startDt: this.startDt,
        endDt: this.endDt,
        pageNumber: this.pageNumber,
      })
      this.mileageHistoryList = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber

      if (setQuery) {
        const query = {
          startDt: this.startDt,
          endDt: this.endDt,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'mypageMileageHistory',
          query,
        })
      }
    },
  },
  async created() {
    this.initDatePicker('startDt', this.setStartDate)
    this.initDatePicker('endDt', this.setEndDate)

    this.setFromQueryParam()

    await this.search(false)
  },
}
</script>

<style lang="scss">
.myp-buy-wrap {
  padding: 30px;
  width: calc(100% - 62px);
}
</style>
