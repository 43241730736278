<template>
  <div class="myp-buy-wrap type01 bot0">
    <div v-if="isVisibleLeftTab">
      <ul class="nlist">
        <li>적립 항목별로 발생할 때 마다 마일리지가 부여됩니다.</li>
        <li>부여된 마일리지는 유효기간이 지나면 자동으로 소멸됩니다.</li>
        <li>적립된 마일리지는 개인별 회원 등급을 결정하기 위해 사용됩니다.</li>
      </ul>
      <table class="tbl01">
        <colgroup>
          <col width="180" />
          <col width="180" />
          <col width="180" />
          <col width="180" />
        </colgroup>
        <tr>
          <th>적립 항목</th>
          <th>부여 마일리지</th>
          <th>유효기간(개월)</th>
          <th>비고</th>
        </tr>
        <tr v-for="(item, idx) in mileageInfoTbl" :key="`st_${idx}`">
          <td style="text-align: center">{{ item.mileagePath }}</td>
          <td style="text-align: right">
            {{
              `${
                isEmployee
                  ? insertCommaToNumber(item.employeeMileage)
                  : insertCommaToNumber(item.employerMileage)
              }`
            }}
          </td>
          <td style="text-align: right">{{ item.expiration }}</td>
          <td>{{ item.remark }}</td>
        </tr>
      </table>
    </div>
    <div v-else>
      <ul class="nlist">
        <li>마일리지는 회원 등급별로 차등 지급 됩니다.</li>
      </ul>
      <table class="tbl01">
        <colgroup>
          <col width="180" />
          <col width="180" />
          <col width="180" />
        </colgroup>
        <tr>
          <th>회원 등급</th>
          <th>부여 마일리지</th>
          <th>마일리지 지급율</th>
        </tr>
        <tr v-for="(item, idx) in mileagePercentInfoTbl" :key="`st_${idx}`">
          <td style="text-align: center">{{ item.mileageCode }}</td>
          <td style="text-align: right">
            {{ `${insertCommaToNumber(item.mileageRange)}` }}
          </td>
          <td style="text-align: right">{{ item.benefitPer }}%</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mileageInfoTbl: Array,
    mileagePercentInfoTbl: Array,
    isVisibleLeftTab: Boolean,
  },
}
</script>

<style></style>
